@use 'src/src/app/scss/abstracts' as *;

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  width: 100dvw;
  height: 100dvh;
  inset: 0;
  background-color: $color-base-black-100;
}
